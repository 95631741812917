
    import {Component, Vue} from 'vue-property-decorator'
    import {MoodleApi} from "@/modules/core/webservice/moodle-api";
    import ErrorUtility from "@/modules/core/utility/ErrorUtility";
    import SettingsUtility from "@/modules/core/utility/SettingsUtility";
    import UserUtility from "@/modules/core/utility/UserUtility";
    import {CalendarItemResponse, CalendarItemsGroup} from "@/modules/calendar/models/response/CalendarResponse";
    import {FilterCourse} from "@/modules/calendar/models/FilterCourse";
    import {UnassignedCalendarCourse} from "@/modules/calendar/models/UnassignedCalendarCourse";
    import {CalendarEvent} from "vuetify";
    import {CalendarItem} from "@/modules/calendar/models/CalendarItem";
    import { Drag, Drop } from "vue-easy-dnd";

    @Component<CalenderWidget>({
        components: {
            Event: SettingsUtility.getSetting("calendar.components.components.event"),
            EventDetails: SettingsUtility.getSetting("calendar.components.components.eventDetails"),
            Notification: SettingsUtility.getSetting("core.components.notification"),
            Drag,
            Drop
        },
        data() {
            return {
                hasCourseCreatorDefaultRole: UserUtility.hasCourseCreatorDefaultRole()
            }
        }
    })
    export default class CalenderWidget extends Vue {
        hasCourseCreatorDefaultRole = UserUtility.hasCourseCreatorDefaultRole()

        focus = ''
        type = 'week'
        typeToLabel = {
            month: 'Monat',
            week: 'Woche',
            day: 'Tag',
        }
        weekdays = [1, 2, 3, 4, 5]
        selectedEvent =  {}
        selectedElement =  null
        selectedOpen =  false

        eventCollection: CalendarEvent[] =  []
        events: CalendarEvent[] =  []

        colors =  [
            {id: 1, name: 'categoryRed'},
            {id: 2, name: 'categoryOrange'},
            {id: 3, name: 'categoryYellow'},
            {id: 4, name: 'categoryGreen'},
            {id: 5, name: 'categoryBlue'},
            {id: 6, name: 'categoryLila'},
            {id: 7, name: 'categoryGrey'},
        ]

        filterCourses: FilterCourse[] = []
        selectedCourse = 0

        unassignedItemsCollection: UnassignedCalendarCourse[] = []
        unassignedItems: UnassignedCalendarCourse[] = []

        eventStartDate = ''
        eventStartDateMenu = false

        eventStartTime = ''
        eventStartTimeMenu = false

        eventEndDate = ''
        eventEndDateMenu = false

        eventEndTime = ''
        eventEndTimeMenu = false

        notification = {}
        notificationActive = false

        intervalFormat(locale: any, getOptions: any) {
            return locale.time;
        }

        allowedSteps(m: any) {
            return m % 5 === 0
        }

        viewDay ({ date }: any) {
            this.focus = date
            this.type = 'day'
        }

        getEventColor (event: any) {
            return event.color
        }

        setToday() {
            this.focus = ''
        }

        prev() {
            // @ts-ignore: Vuetify error
            this.$refs.calendar.prev()
        }

        next() {
            // @ts-ignore: Vuetify error
            this.$refs.calendar.next()
        }

        showEvent ({ nativeEvent, event }: any) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target

                this.setEventEditStartDateFromTimestamp(event.startDate)
                this.setEventEditEndDateFromTimestamp(event.endDate)

                setTimeout(() => {
                    this.selectedOpen = true
                }, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                setTimeout(open, 10)
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        }

        getActiveCourseFilterName(): string | boolean {
            if(this.filterCourses.length > 0) {
                const course = this.filterCourses.find((item: FilterCourse)  => item.id === this.selectedCourse);
                return course ? course.name : false;
            }
            return false;
        }

        getFormattedDate(date: Date): string {
            if (typeof date === 'undefined') {
                return ''
            }

            const days = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
            const dayOfWeek = days[date.getDay()]

            const year = date.getFullYear()

            let month = (1 + date.getMonth()).toString()
            month = month.length > 1 ? month : '0' + month

            let day = date.getDate().toString()
            day = day.length > 1 ? day : '0' + day

            let hours = date.getHours().toString()
            hours = hours.length > 1 ? hours : '0' + hours

            let minutes = date.getMinutes().toString()
            minutes = minutes.length > 1 ? minutes : '0' + minutes

            return dayOfWeek + ' ' + day + '.' + month + '.' + year + ' ' + hours + ':' + minutes
        }

        filterByCourse(courseId: number) {
            this.selectedCourse = courseId

            if (courseId > 0) {
                this.events = this.eventCollection.filter((item: CalendarEvent) => {
                    return item.courseId === courseId;
                })
                this.unassignedItems = this.unassignedItemsCollection.filter((item: UnassignedCalendarCourse) => {
                    return item.courseId === courseId;
                })
            } else {
                this.events = this.eventCollection
                this.unassignedItems = this.unassignedItemsCollection
            }
        }

        setUnassignedCalendarItems(calendarItemsGroup: CalendarItemsGroup[]) {
            if(calendarItemsGroup.length > 0) {
                const unassignedItemsCollection: UnassignedCalendarCourse[] = [];

                calendarItemsGroup.forEach((group)=> {
                    let unassignedItems = group.calendar_items.filter((item: CalendarItemResponse) => {
                        return !item.assigned;
                    })

                    if(unassignedItems.length > 0) {
                        unassignedItemsCollection.push({
                            courseId: group.course_id,
                            courseName: group.course_name,
                            courseNumberStudents: group.course_number_students,
                            isTeacher: group.is_teacher,
                            categoryId: group.category_id,
                            categoryName: group.category_name,
                            color: group.category_color,
                            items: unassignedItems.map((task: CalendarItemResponse) => {
                                return {
                                    id: task.id,
                                    name: task.name,
                                    type: task.type,
                                    assigned: task.assigned,
                                    start_date: task.start_date,
                                    end_date: task.end_date,
                                    next_mod_type: task.next_mod_type ?? '',
                                    next_mod_id: task.next_mod_id ?? 0
                                }
                            })
                        })
                    }
                })

                this.unassignedItemsCollection = unassignedItemsCollection
                this.unassignedItems = unassignedItemsCollection
            }
        }

        setCalendarEvents(calendarItemsGroup: CalendarItemsGroup[]) {
            if(calendarItemsGroup.length > 0) {
                const events: CalendarEvent[] = [];

                calendarItemsGroup.forEach((group)=> {
                    const assignedItems = group.calendar_items.filter((item: CalendarItemResponse) => {
                        return item.assigned;
                    })

                    assignedItems.forEach((item: CalendarItemResponse) => {
                        events.push({
                            name: item.name,
                            start: new Date(item.start_date * 1000),
                            end: new Date(item.end_date * 1000),
                            color: this.getColorName(group.category_color),
                            timed: item.start_date !== 0,
                            itemId: item.id,
                            itemType: item.type,
                            startDate: item.start_date,
                            endDate: item.end_date,
                            courseId: group.course_id,
                            courseName: group.course_name,
                            numberStudents: group.course_number_students,
                            isTeacher: group.is_teacher,
                            categoryId: group.category_id,
                            categoryName: group.category_name,
                            categoryColor: group.category_color,
                            next_mod_type: item.next_mod_type ?? '',
                            next_mod_id: item.next_mod_id ?? 0
                        })
                    })
                })

                this.eventCollection = events
                this.events = events
            }
        }

        getColorName(id: number) {
            if(id) {
                const color = this.colors.find((item: any) => {
                    return item.id == id
                });
                return color ? color.name : 'primary'
            }
        }

        mounted() {
            // @ts-ignore: Vuetify error
            this.$refs.calendar.checkChange()
        }

        async created() {
            const response: CalendarItemsGroup[] = await MoodleApi.get('local_lumaconi_get_calendar_items', {});

            ErrorUtility.parseResponse(response);
            if(ErrorUtility.hasError()) {
                this.notification = response
                this.notificationActive = true
            } else {
                if(response.length > 0) {
                    // Add options to calendar course filter
                    const filterCourses = response.map((item: CalendarItemsGroup)=>{
                        return {
                            id: item.course_id,
                            name: item.course_name
                        }
                    })
                    this.filterCourses = [{id: 0, name: 'Alle Handlungssituationen'}, ...filterCourses];

                    this.setUnassignedCalendarItems(response);
                    this.setCalendarEvents(response);
                }
            }
        }

        resetDateEditFields() {
            this.eventStartDate = ''
            this.eventStartDateMenu = false

            this.eventStartTime = ''
            this.eventStartTimeMenu = false

            this.eventEndDate = ''
            this.eventEndDateMenu = false

            this.eventEndTime = ''
            this.eventEndTimeMenu = false
        }

        closeEventDetail() {
            this.resetDateEditFields()
            this.selectedOpen = false
        }

        async updateCalendarItem(event: CalendarEvent) {
            const response = await MoodleApi.get('local_lumaconi_assign_calendar_item', {
                course_id: event.courseId,
                id: event.itemId,
                type: event.itemType,
                start_date: this.getEventEditStartDateTimestamp(),
                end_date: this.getEventEditEndDateTimestamp(),
            });

            ErrorUtility.parseResponse(response);
            if (ErrorUtility.hasError()) {
                this.notification = response
                this.notificationActive = true
            }

            this.resetDateEditFields()
            this.selectedOpen = false

            //refresh view
            this.$router.go(0)
        }

        async deleteCalendarItem(event: CalendarEvent) {
            const response = await MoodleApi.get('local_lumaconi_assign_calendar_item', {
                course_id: event.courseId,
                id: event.itemId,
                type: event.itemType,
                start_date: 0,
                end_date: 0,
            });

            ErrorUtility.parseResponse(response);
            if (ErrorUtility.hasError()) {
                this.notification = response
                this.notificationActive = true
            }

            this.resetDateEditFields()
            this.selectedOpen = false

            //refresh view
            this.$router.go(0)
        }

        goToTopic(event: CalendarEvent, hasCourseCreatorDefaultRole: boolean) {
            if (event.itemType === 'exam') {
                if (hasCourseCreatorDefaultRole) {
                    this.$router.push({path: '/course/' + event.courseId + '/quiz/' + event.itemId + '/edit'})
                } else {
                    this.$router.push({path: '/course/' + event.courseId + '/quiz/' + event.itemId})
                }
            } else {
                if (hasCourseCreatorDefaultRole) {
                    this.$router.push({path: '/course/detail/' + event.courseId})
                } else {
                    if (event.next_mod_type === '' || event.next_mod_id === 0) {
                        this.$router.push({path: '/course/detail/' + event.courseId})
                    } else {
                        this.$router.push({path: '/course/' + event.courseId + '/' + event.next_mod_type + '/' + event.next_mod_id})
                    }
                }
            }
        }

        getEventEditStartDateTimestamp(): number {
            if (!this.eventStartDate) {
                return 0;
            }

            let [year, month, day] = this.eventStartDate.split('-');
            let hour = 0, minute = 0;

            if (this.eventStartTime) {
                let timeParts = this.eventStartTime.split(':');
                hour = parseInt(timeParts[0]);
                minute = parseInt(timeParts[1]);
            }

            const eventEditStartDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour, minute);
            return (eventEditStartDate.getTime() / 1000);
        }

        getEventEditEndDateTimestamp(): number {
            if (!this.eventEndDate) {
                return 0;
            }

            let [year, month, day] = this.eventEndDate.split('-');
            let hour = 0, minute = 0;

            if (this.eventEndTime) {
                let timeParts = this.eventEndTime.split(':');
                hour = parseInt(timeParts[0]);
                minute = parseInt(timeParts[1]);
            }

            const eventEditEndDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour, minute);
            return (eventEditEndDate.getTime() / 1000);
        }

        setEventEditStartDateFromTimestamp(timestamp: number) {
            let date = new Date(timestamp * 1000);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});
            let day = date.getDate().toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});
            let hours = date.getHours().toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});
            let minutes = date.getMinutes().toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});

            this.eventStartDate = `${year}-${month}-${day}`;
            this.eventStartTime = `${hours}:${minutes}`;
        }

        setEventEditEndDateFromTimestamp(timestamp: number) {
            let date = new Date(timestamp * 1000);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});
            let day = date.getDate().toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});
            let hours = date.getHours().toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});
            let minutes = date.getMinutes().toLocaleString('de-DE',{minimumIntegerDigits: 2, useGrouping: false});

            this.eventEndDate = `${year}-${month}-${day}`;
            this.eventEndTime = `${hours}:${minutes}`;
        }

        // ----------------------
        // Drag 'n' Drop Logic
        // ----------------------
        dragEvent: any = null
        dragStart: any = null
        dragTime: any = null
        extendOriginal: any = null
        createEvent: any = null
        createStart: any = null

        startDrag({event, timed}: any) {
            if (this.hasCourseCreatorDefaultRole && event && timed) {
                this.dragEvent = event
                this.dragTime = null
                this.extendOriginal = null
            }
        }

        startTime(tms: any) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start
                this.dragTime = mouse - start
            }
        }

        extendBottom(event: any) {
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
        }

        mouseMove(tms: any) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start
                const end = this.dragEvent.end
                const duration = end - start
                const newStartTime = mouse - this.dragTime
                const newStart = this.roundTime(newStartTime)
                const newEnd = newStart + duration

                this.dragEvent.start = newStart
                this.dragEvent.end = newEnd
            }
            else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.createStart)
                const max = Math.max(mouseRounded, this.createStart)

                this.createEvent.start = min
                this.createEvent.end = max
            }
        }

        async endDrag(tms: any) {
            if (this.dragEvent) {
                const response = await MoodleApi.get('local_lumaconi_assign_calendar_item', {
                    course_id: this.dragEvent.courseId,
                    id: this.dragEvent.itemId,
                    type: this.dragEvent.itemType,
                    start_date: (this.dragEvent.start / 1000),
                    end_date: (this.dragEvent.end / 1000),
                })
                ErrorUtility.parseResponse(response)
                if (ErrorUtility.hasError()) {
                    this.notification = response
                    this.notificationActive = true
                }
            } else if (this.createEvent && this.createStart !== null) {
                const response = await MoodleApi.get('local_lumaconi_assign_calendar_item', {
                    course_id: this.createEvent.courseId,
                    id: this.createEvent.itemId,
                    type: this.createEvent.itemType,
                    start_date: (this.createEvent.start / 1000),
                    end_date: (this.createEvent.end / 1000),
                })
                ErrorUtility.parseResponse(response)
                if (ErrorUtility.hasError()) {
                    this.notification = response
                    this.notificationActive = true
                }
            }

            this.dragTime = null
            this.dragEvent = null
            this.extendOriginal = null
            this.createEvent = null
            this.createStart = null
        }

        cancelDrag() {
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal
                } else {
                    const i = this.events.indexOf(this.createEvent)
                    if (i !== -1) {
                        this.events.splice(i, 1)
                    }
                }
            }

            this.createEvent = null
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
        }

        toTime(tms: any) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        }

        roundTime(time: number, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
                ? time - time % roundDownTime
                : time + (roundDownTime - (time % roundDownTime))
        }

        onNewCalendarItemDrop(event: any) {
            // top is dropzone
            let year = event.top.$attrs['data-slot-year']
            let month = event.top.$attrs['data-slot-month']
            let day = event.top.$attrs['data-slot-day']
            let hour = event.top.$attrs['data-slot-hour']
            let minute = event.top.$attrs['data-slot-minute']

            // source is draggable item (calendar item)
            let newCalendarItemId = event.source.$attrs['data-new-calendar-item-id']

            let unassignedCalendarCourse: UnassignedCalendarCourse | undefined = undefined
            let calendarItem: CalendarItem | undefined = undefined

            for (let unassignedCalendarCourseItem of this.unassignedItems as Array<UnassignedCalendarCourse>) {
                calendarItem = unassignedCalendarCourseItem.items.find((unassignedCalendarItem: CalendarItem) => {
                    return unassignedCalendarItem.id === newCalendarItemId
                })

                if (typeof calendarItem !== 'undefined') {
                    unassignedCalendarCourse = unassignedCalendarCourseItem
                    break
                }
            }

            if (typeof calendarItem === 'undefined') {
                return
            }

            if (typeof unassignedCalendarCourse === 'undefined') {
                return
            }

            // Remove from unassigned items
            const index = unassignedCalendarCourse.items.indexOf(calendarItem);
            if (index > -1) {
                unassignedCalendarCourse.items.splice(index, 1);
            }

            // Add to calendar
            const startDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour, minute);
            const endDate = new Date(parseInt(year), parseInt(month) - 1, parseInt(day), hour, minute);
            endDate.setHours(endDate.getHours() + 2)
            this.assignNewCalendarItem(calendarItem, unassignedCalendarCourse, startDate, endDate)
        }

        assignNewCalendarItem(item: CalendarItem, group: UnassignedCalendarCourse, newStartDate: Date, newEndDate: Date) {
            const newEvent: CalendarEvent = {
                name: item.name,
                start: newStartDate,
                end: newEndDate,
                color: this.getColorName(group.color),
                timed: true,
                itemId: item.id,
                itemType: item.type,
                startDate: (newStartDate.getTime() / 1000),
                endDate: (newEndDate.getTime() / 1000),
                courseId: group.courseId,
                courseName: group.courseName,
                numberStudents: group.courseNumberStudents,
                isTeacher: group.isTeacher,
                categoryId: group.categoryId,
                categoryName: group.categoryName,
                categoryColor: group.color,
                next_mod_type: item.next_mod_type ?? '',
                next_mod_id: item.next_mod_id ?? 0
            }

            this.events.push(newEvent)
        }
    }
